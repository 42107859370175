<template>
    <div class="module_div">
        <div class="content module7">
            <div class="serviceInfo_data">
                <ul class="serviceInfo_ul Between flex-wrap">
                    <li class="serviceInfo_li" v-for="(item, index) in list" :key="index">
                        <div class="Between">
                            <div class="serviceInfo_li_title">
                                {{ item.labourServiceName }}
                            </div>
                            <div class="time">2023-1-2</div>
                        </div>
                        <div class="Between cont_div">
                            <div class="serviceInfo_li_content" :class="item.isMOre == true ? 'isMOre' : 'row'">
                                {{ item.labourServiceContent }}
                            </div>
                            <div class="more flex-center pointer" @click="item.isMOre = !item.isMOre">
                                {{ item.isMOre == true ? '收起' : '更多'
                                }}<img src="@/assets/img/x.png" alt="" />
                            </div>
                        </div>
                        <div v-if="item.isMOre == true" class="serviceInfo_li_btn display pointer" @click="service">
                            立即咨询
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        list: {},
    },
    data() {
        return {}
    },
    mounted() { },
    methods: {
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.module_div {
    padding-top: 14px;

    .module7 {
        .serviceInfo_data {
            .serviceInfo_ul {
                box-sizing: border-box;

                .serviceInfo_li {
                    width: 1200px;
                    background: #ffffff;
                    padding: 30px;
                    box-sizing: border-box;
                    position: relative;
                    margin-bottom: 20px;

                    .cont_div {
                        margin-top: 18px;
                        position: relative;
                    }

                    .serviceInfo_li_title {
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 24px;
                    }

                    .serviceInfo_li_content {
                        width: 730px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                    }

                    .more {
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #003c7e;
                        position: absolute;
                        top: 0;
                        right: 0px;

                        img {
                            margin-left: 5px;
                        }
                    }

                    .isMOre {
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                    }

                    .serviceInfo_li_btn {
                        width: 140px;
                        height: 48px;
                        background: #ff881e;
                        border-radius: 5px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        margin-top: 27px;
                    }
                }
            }
        }
    }
}
</style>