<template>
	<div class="serviceInfo_page">
		<BannerImg
			:bannerImgUrl="$store.state.Information.labourServiceBannerImgUrl"
		></BannerImg>
		<div class="serviceInfo_main">
			<CourseTitle title="劳务信息"> </CourseTitle>
			<div class="content">
				<Module7 :list="list"></Module7>
				<div class="page_div flex">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						background
						:current-page="1"
						layout="prev, pager, next"
						:total="total"
						:page-size="params.limit"
					>
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from './components/courseTitle.vue'
import Module7 from './components/personalService/module7'
export default {
	components: {
		BannerImg,
		CourseTitle,
		Module7,
	},
	data() {
		return {
			total: 0,
			list: [],
			params: {
				page: 1,
				limit: 8,
			},
		}
	},
	mounted() {
		this.init()
		document.documentElement.scrollTop = 0
	},
	methods: {
		init() {
			this.api.serviceInfoApi(this.params).then((res) => {
				console.log(res)
				this.total = res.count
				if (res.data.length != 0) {
					res.data.forEach((item) => {
						this.$set(item, 'isMOre', false)
					})
				}
				this.list = res.data
			})
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`)
		},
		handleCurrentChange(val) {
			this.params.page = val
			this.init()
		},
	},
}
</script>
<style lang="scss" scoped>
.serviceInfo_page {
	background: #f4f5f5;
	.content {
		margin-top: 20px;
	}
	.serviceInfo_main {
		padding-bottom: 76px;
		.page_div {
			margin-top: 30px;
			justify-content: flex-end;
		}
	}
}
</style>